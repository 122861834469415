.product {
  margin: var(--space-30) 0 var(--space-42);

  @include tablet-portrait {
    margin: var(--space-56) 0 var(--space-56);
  }

  @include desktop {
    margin: var(--space-120) 0;
  }
}

.product__content {
  @include grid;
}

.product__media,
.product__info {
  grid-column: 1 / -1;
}

.product__media {
  margin-bottom: var(--space-30);

  @include tablet-portrait {
    grid-column: span 5;
    margin-bottom: 0;
  }

  @include tablet-landscape {
    grid-column: span 4;
  }
}

.product__info {
  @include tablet-portrait {
    display: flex;
    flex-direction: column;
    grid-column: 6 / span 4;
  }
}

.product__title {
  margin-bottom: 0.25em;
  color: var(--color-pink);
  text-transform: uppercase;

  @include text-20;

  @include tablet-portrait {
    @include text-30;
  }

  @include tablet-landscape {
    @include text-35;
  }
}

.product__price {
  margin-bottom: var(--space-20);

  @include font-sans;
  @include text-15;

  font-weight: bold;

  @include tablet-portrait {
    @include text-20;
  }
}

.product__selection {
  display: flex;
  flex-direction: column;

  @include tablet-portrait {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: auto;
  }
}

.product__selection__options {
  width: 100%;
  margin-bottom: var(--space-20);

  @include desktop {
    width: max-content;
    margin-right: var(--space-42);
  }
}

.product__selection__field {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--space-20);

  @include tablet-portrait {
    margin-bottom: 0;
  }
}

.product__selection__input {
  display: block;
  flex: 1;
  margin: 0 0.25em;
  padding: 0.25em 0;
  text-align: center;

  &::selection {
    background: transparent;
  }

  @include tablet-portrait {
    width: 3em;
  }
}

.product__selection__button {
  width: 100%;
  border: 1px solid var(--color-pink);
  white-space: nowrap;

  @include tablet-landscape {
    width: 2em;
  }
}

.product__related {
  max-width: var(--maxwidth);
  padding: 0 var(--margin);
  margin: var(--space-120) auto 0;
}

.product__related__content {
  padding-top: var(--space-80);
  border-top: 1px solid var(--color-text);
}

.product__related__heading,
.product__related__list {
  grid-column: 1 / -1;
}

.product__related__heading {
  text-align: center;
  text-transform: uppercase;

  @include text-25;

  @include tablet-portrait {
    @include text-30;
  }

  @include tablet-portrait {
    @include text-40;
  }
}

.product__related__list {
  width: calc(100% + 2 * var(--margin));
  margin-left: calc(var(--margin) * -1);
}
