:root {
  /*
		Primary
	*/

  /* Default */
  --color-buttonPrimary: var(--color-white);
  --borderColor-buttonPrimary: var(--color-pink);
  --bgColor-buttonPrimary: var(--color-pink);

  /* Hover */
  --color-buttonPrimary-hover: var(--color-white);
  --borderColor-buttonPrimary-hover: var(--color-pink);
  --bgColor-buttonPrimary-hover: var(--color-pink);
  --shadow-buttonPrimary-hover: var(--hover-shadow);

  /* Active */
  --color-buttonPrimary-active: var(--color-white);
  --borderColor-buttonPrimary-active: var(--color-pink);
  --bgColor-buttonPrimary-active: var(--color-pink);
  --shadow-buttonPrimary-active: var(--hover-shadow);

  /* Disabled */
  --color-buttonPrimary-disabled: var(--color-white);
  --borderColor-buttonPrimary-disabled: var(--color-gray);
  --bgColor-buttonPrimary-disabled: var(--color-gray);

  /* Success */
  --color-buttonPrimary-success: var(--color-white);
  --borderColor-buttonPrimary-success: var(--color-success);
  --bgColor-buttonPrimary-success: var(--color-success);

  /* Error */
  --color-buttonPrimary-error: var(--color-white);
  --borderColor-buttonPrimary-error: var(--color-error);
  --bgColor-buttonPrimary-error: var(--color-error);

  /*
		Secondary
	*/

  /* Default */
  --color-buttonSecondary: var(--color-white);
  --borderColor-buttonSecondary: var(--color-blue);
  --bgColor-buttonSecondary: var(--color-blue);

  /* Hover */
  --color-buttonSecondary-hover: var(--color-white);
  --borderColor-buttonSecondary-hover: var(--color-blue);
  --bgColor-buttonSecondary-hover: var(--color-blue);
  --shadow-buttonSecondary-hover: var(--hover-shadow);

  /* Active */
  --color-buttonSecondary-active: var(--color-white);
  --borderColor-buttonSecondary-active: var(--color-blue);
  --bgColor-buttonSecondary-active: var(--color-blue);
  --shadow-buttonSecondary-active: var(--hover-shadow);

  /* Disabled */
  --color-buttonSecondary-disabled: var(--color-white);
  --borderColor-buttonSecondary-disabled: var(--color-gray);
  --bgColor-buttonSecondary-disabled: var(--color-gray);

  /* Success */
  --color-buttonSecondary-success: var(--color-white);
  --borderColor-buttonSecondary-success: var(--color-success);
  --bgColor-buttonSecondary-success: var(--color-success);

  /* Error */
  --color-buttonSecondary-error: var(--color-white);
  --borderColor-buttonSecondary-error: var(--color-error);
  --bgColor-buttonSecondary-error: var(--color-error);
}
