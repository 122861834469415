:root {
  /* Default */
  --color-link: var(--color-pink);
  --borderColor-link: var(--color-pink);
  --bgColor-link: none;

  /* Hover */
  --color-link-hover: var(--color-pink);
  --borderColor-link-hover: var(--color-pink);
  --bgColor-link-hover: none;

  /* Active */
  --color-link-active: var(--color-pink);
  --borderColor-link-active: var(--color-pink);
  --bgColor-link-active: none;

  /* Focus */
  --color-link-focus: var(--color-pink);
  --borderColor-link-focus: var(--color-pink);
  --bgColor-link-focus: var(--color-pink);
  --shadow-link-focus: none;
}
