@font-face {
  font-family: 'Londrina Solid';
  src: url('../../src/assets/fonts/LondrinaSolid-Regular.woff2') format('woff2'),
    url('../../src/assets/fonts/LondrinaSolid-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../src/assets/fonts/Montserrat-MediumItalic.woff2')
      format('woff2'),
    url('../../src/assets/fonts/Montserrat-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../src/assets/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('../../src/assets/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../src/assets/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('../../src/assets/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
