.navigation,
.navigation__overlay,
.navigation__content {
  position: absolute;
  top: 0;
  height: 100vh;
  max-height: 100%;
}

.navigation,
.navigation__overlay {
  left: 0;
  width: 100vw;
}

.navigation {
  pointer-events: none;
  overflow: hidden;
  z-index: z('navigation');

  .admin-bar & {
    top: 32px;
  }

  .is-open & {
    pointer-events: auto;
  }

  @include tablet-landscape {
    display: none;
  }
}

.navigation__close {
  position: absolute;
  top: var(--space-42);
  right: var(--space-30);
  color: var(--color-pink);
  width: 1.875rem;
  height: 1.875rem;

  svg {
    width: 100%;
    height: 100%;
  }
}

.navigation__overlay {
  background-color: #000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;

  .is-open & {
    pointer-events: auto;
    opacity: 0.5;
  }
}

.navigation__content {
  right: 0;
  padding: var(--space-42) var(--space-30);
  background-color: var(--color-white);
  transform: translateX(100%);
  box-shadow: -0.4px 0px 2.2px rgba(0, 0, 0, 0.028),
    -1px 0px 5.3px rgba(0, 0, 0, 0.04), -1.9px 0px 10px rgba(0, 0, 0, 0.05),
    -3.4px 0px 17.9px rgba(0, 0, 0, 0.06),
    -6.3px 0px 33.4px rgba(0, 0, 0, 0.072), -15px 0px 80px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  .is-open & {
    transform: translateX(0);
  }
}

.navigation__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @include font-heading;
  @include text-20;
}

.navigation__item {
  margin-bottom: 0.75em;
  width: max-content;

  &.is-highlighted {
    background-color: var(--color-pink);
  }
}

.navigation__link {
  white-space: nowrap;

  .is-active & {
    color: var(--color-pink);
  }

  .is-highlighted & {
    display: block;
    padding: 0.25em 0.75em;
    color: var(--color-white);
    text-transform: uppercase;
  }
}
