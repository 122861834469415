@mixin font-sans {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-weight: 500;
}

@mixin font-heading {
  font-family: 'Londrina Solid', Helvetica, Arial, sans-serif;
  font-weight: normal;
}

@mixin text-12 {
  font-size: 0.75rem;
  line-height: 1.6666666667;
}

@mixin text-15 {
  font-size: 0.9375rem;
  line-height: 1.6666666667;
}

@mixin text-20 {
  font-size: 1.25rem;
  line-height: 1.25;
}

@mixin text-25 {
  font-size: 1.5625rem;
  line-height: 1.25;
}

@mixin text-30 {
  font-size: 1.875rem;
  line-height: 1.3333333333;
}

@mixin text-35 {
  font-size: 2.1875rem;
  line-height: 1;
}

@mixin text-40 {
  font-size: 2.5rem;
  line-height: 1;
}

@mixin text-70 {
  font-size: 4.375rem;
  line-height: 1;
}
