.entries {
  row-gap: var(--space-56);
  padding: var(--space-56) 0;

  @include grid;

  > * {
    grid-column: 1 / -1;

    @include tablet-portrait {
      grid-column: 1 / span 4;

      &:nth-child(2n) {
        grid-column: 6 / span 4;
      }
    }
  }

  @include tablet-portrait {
    row-gap: var(--space-120);
  }
}
