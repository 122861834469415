.list {
  padding: var(--space-30) 0;
  row-gap: var(--space-56);

  @include grid;

  grid-template-columns: 1fr;

  @include tablet-portrait {
    grid-template-columns: repeat(2, 1fr);
    padding: var(--space-42) 0;
  }

  @include tablet-landscape {
    grid-template-columns: repeat(3, 1fr);
    padding: var(--space-72) 0;
  }

  @include desktop {
    padding: var(--space-80) 0;
  }
}
