@mixin phablet {
  @media (min-width: 390px) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (min-width: 609px) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: 919px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width: 1920px) {
    @content;
  }
}
