.header {
  display: flex;
  justify-content: center;
  height: 6rem;
  background: var(--color-white);
  box-shadow: 0px 0.3px 2.2px rgba(0, 0, 0, 0.028),
    0px 0.7px 5.3px rgba(0, 0, 0, 0.04), 0px 1.3px 10px rgba(0, 0, 0, 0.05),
    0px 2.2px 17.9px rgba(0, 0, 0, 0.06), 0px 4.2px 33.4px rgba(0, 0, 0, 0.072),
    0px 10px 80px rgba(0, 0, 0, 0.1);
  z-index: z('header');
}

.header__content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: var(--maxwidth);
  margin: var(--space-20) var(--margin);

  @include font-heading;

  @include desktop {
    margin: var(--space-20) auto;
  }
}

.header__logo {
  width: auto;
  height: 2.75rem;
  margin-right: auto;
  color: var(--color-pink);

  svg {
    height: 100%;
    width: auto;
  }

  @include tablet-landscape {
    height: 3.75rem;
  }
}

.header__navigation {
  display: none;

  @include tablet-landscape {
    display: block;
  }
}

.header__navigation__list {
  display: flex;
  align-items: center;
  margin-bottom: 0;

  @include text-20;
}

.header__navigation__item {
  &.is-highlighted {
    background-color: var(--color-pink);
  }

  @include tablet-landscape {
    &:not(:last-child) {
      margin-right: var(--space-30);
    }
  }

  @include desktop {
    &:not(:last-child) {
      margin-right: var(--space-56);
    }
  }
}

.header__navigation__link {
  display: block;
  color: var(--color-text);

  .is-active & {
    color: var(--color-pink);
  }

  .is-highlighted & {
    padding: 0.25em 0.75em;
    color: var(--color-white);
    text-transform: uppercase;
  }
}

.header__cart {
  display: flex;
  align-items: center;
  margin-right: var(--space-30);
  color: var(--color-pink);
  cursor: pointer;

  @include tablet-landscape {
    margin-right: 0;
    margin-left: var(--space-30);
  }

  @include desktop {
    margin-left: var(--space-56);
  }
}

.header__cart__icon {
  width: 1.75rem;
  height: 1.6875rem;

  svg {
    width: 100%;
    height: 100%;
  }
}

.header__cart__count {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.25rem;
  background: var(--color-pink);
  color: var(--color-white);
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 9999px;
}

.header__burger {
  width: 2.5625rem;
  height: 1.375rem;
  color: var(--color-pink);

  svg {
    width: 100%;
    height: 100%;
  }

  @include tablet-landscape {
    display: none;
  }
}
