.intro {
  margin-bottom: var(--space-30);
  padding: var(--space-56) 0;
  background-color: var(--color-pink);

  @include tablet-landscape {
    margin-bottom: var(--space-56);
  }
}

.intro__content {
  @include grid;
}

.intro__heading {
  grid-column: 1 / -1;
  max-width: 45ch;
  margin: 0 auto;
  color: var(--color-white);
  text-align: center;

  @include text-20;

  @include tablet-portrait {
    @include text-30;
  }
}
