.bestsellers {}

.bestsellers__content {
	padding: var(--space-80) 0;

	@include grid;
}

.bestsellers__heading,
.bestsellers__products,
.bestsellers__link {
	grid-column: 1 / -1;
}

.bestsellers__heading {
	text-align: center;
	text-transform: uppercase;

  @include text-25;

  @include tablet-portrait {
    @include text-30;
  }

  @include tablet-portrait {
    @include text-40;
  }
}

.bestsellers__items {
	margin-bottom: var(--space-56);
}

.bestsellers__link {
  display: flex;
  margin: 0 auto;
  align-items: center;
	color: var(--color-pink);
	text-align: center;
	text-transform: uppercase;

  span svg {
    height: 1em;
    margin-left: 0.25em;
  }

	@include font-heading;
  @include text-25;

  @include tablet-portrait {
    @include text-30;
  }

  @include tablet-portrait {
    @include text-40;
  }
}
