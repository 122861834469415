:root {
  /* Icon */
  --color-icon: var(--color-pink);

  /* Success */
  --color-success: var(--color-white);
  --bgColor-success: var(--color-success);

  /* Error */
  --color-error: var(--color-white);
  --borderColor-error: var(--color-error);
  --bgColor-error: var(--color-error);

  /* Info */
  --bgColor-info: var(--color-white);

  /* Modal */
  --bgColor-modal: var(--color-gray);
  --bgColor-modalVeil: #000;
}

#snipcart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;

  .snipcart-sidecart--opened & {
    opacity: 0.5;
    pointer-events: auto;
  }
}
