.filter {
  @include grid;

  @include tablet-portrait {
    padding-top: var(--space-20);
  }

  @include desktop {
    padding-top: var(--space-30);
  }
}

.filter__content {
  grid-column: 1 / -1;
}

.filter__content,
.filter__list {
  display: flex;
  flex-wrap: wrap;

  @include tablet-landscape {
    align-items: center;
  }
}

.filter__heading {
  margin-bottom: 0.5em;
  margin-right: 1em;
  color: var(--color-pink);
  text-transform: uppercase;

  @include text-25;

  @include desktop {
    margin-bottom: 0;
    margin-right: 2em;
  }
}

.filter__list {
  margin-bottom: 0;
}

.filter__item {
  margin-right: 1em;
  margin-bottom: 0.5em;
  color: var(--color-text);

  @include desktop {
    margin-right: 2em;
  }
}

.filter__link {
  color: var(--color-text);

  &.is-active {
    color: var(--color-pink);
    font-weight: bold;
  }
}
