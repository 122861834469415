.entry {
  > a {
    display: block;
    color: currentColor;
  }
}

.entry__media {
  margin-bottom: var(--space-30);

  @include tablet-portrait {
    margin-bottom: var(--space-42);
  }
}

.entry__title {
  margin-bottom: var(--space-20);
  text-transform: uppercase;
  color: var(--color-pink);

  @include text-30;

  @include tablet-portrait {
    @include text-35;
  }
}
