body {
  width: 100%;
  overflow-x: hidden;
  color: var(--color-text);

  @include font-sans;
}

p,
ol,
ul {
  margin-bottom: 1.66667em;

  @include text-15;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-heading;
}

a {
  color: var(--color-pink);
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.button,
.input {
  height: 2.25rem;
  padding: 0.25em 0.75em;
  outline: none;

  @include font-sans;
  @include text-15;
}

.button {
  display: inline-block;
  background-color: var(--color-pink);
  color: var(--color-white);
  border: 1px solid var(--color-pink);
  text-transform: uppercase;
  cursor: pointer;

  @include font-heading;

  @include tablet-landscape {
    @include text-20;
  }
}

.input {
  border: 1px solid currentColor;
}
