:root {
  /* Default (completed) */
  --color-badge: var(--color-white);
  --borderColor-badge: var(--color-pink);
  --bgColor-badge: var(--color-pink);

  /* Active */
  --color-badge-active: var(--color-white);
  --borderColor-badge-active: var(--color-blue);
  --bgColor-badge-active: var(--color-blue);

  /* Disabled */
  --color-badge-disabled: var(--color-text);
  --borderColor-badge-disabled: var(--color-gray);
  --bgColor-badge-disabled: var(--color-gray);
}
