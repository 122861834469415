.counter {
	background-color: var(--color-gray);
}

.counter__content {
	padding: var(--space-80) 0;
	row-gap: var(--space-56);

	@include grid;

	@include tablet-portrait {
		padding: var(--space-80) 0 var(--space-56);
	}
}

.counter__group {
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include tablet-portrait {
		grid-column: span 4;
		flex-direction: row;
		align-items: flex-start;

		&:nth-child(2n) {
			grid-column: 6 / span 4;
		}
	}
}

.counter__group__illustration {
	width: 50%;
	margin-bottom: var(--space-30);
	color: var(--color-pink);

	@include tablet-landscape {
		width: calc(50% - var(--gutter));
		margin-right: var(--gutter);
	}
}

.counter__group__text {
	@include font-heading;
}

.counter__group__number,
.counter__group__desc {
	width: max-content;
}

.counter__group__number {
	overflow: hidden;
	color: var(--color-pink);

	span {
		display: inline-block;
		transform: translateY(50%);
		opacity: 0;
		transition-property: all;
		transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
		transition-duration: 0.4s;
		transition-delay: var(--delay);

		.is-animating & {
			transform: translateY(0);
			opacity: 1;
		}
	}

	@include text-40;

	@include tablet-landscape {
		@include text-70;
	}
}

.counter__group__desc {
	@include text-25;
}
