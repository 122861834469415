.footer {
  padding: var(--space-30) 0 var(--space-56);
  background-color: var(--color-gray);

  @include tablet-portrait {
    padding: var(--space-56) 0 var(--space-120);
  }
}

.footer__content {
  @include grid;
}

.footer__col {
  grid-column: 1 / -1;

  &:not(:last-child) {
    margin-bottom: var(--space-42);
  }

  @include tablet-portrait {
    grid-column: span 3;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.footer__text,
.footer__text p {
  @include text-12;
}

.footer__text {
  max-width: 36ch;

  p {
    margin-bottom: 1em;
  }
}

.footer__heading {
  margin-bottom: 0.75em;
  text-transform: uppercase;
  color: var(--color-pink);

  @include text-15;
}

.footer__logo {
  width: 8.25rem;
  margin-bottom: 1rem;

  svg {
    width: 100%;
    height: auto;
  }
}

.footer__link {
  &::after {
    content: '';
    display: inline-block;
    width: 0.8125rem;
    height: 0.8125rem;
    margin-bottom: -0.175em;
    margin-left: 0.5em;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.986 12.985'%3E%3Cpath data-name='Union 4' d='M0 6.493A6.5 6.5 0 0 1 6.492 0a6.5 6.5 0 0 1 6.493 6.493 6.5 6.5 0 0 1-6.493 6.493A6.5 6.5 0 0 1 0 6.493Zm1 0a5.5 5.5 0 0 0 5.493 5.493 5.5 5.5 0 0 0 5.493-5.493A5.5 5.5 0 0 0 6.492 1 5.5 5.5 0 0 0 1 6.493Zm5.14 2.712a.5.5 0 0 1 0-.707l1.5-1.5H4.158a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h3.481l-1.5-1.5a.5.5 0 0 1 0-.707.5.5 0 0 1 .707 0L9.205 6.15a.5.5 0 0 1 .147.354.5.5 0 0 1-.147.354L6.846 9.216a.5.5 0 0 1-.353.146.5.5 0 0 1-.354-.162Z' fill='%23e5007d'/%3E%3C/svg%3E");
  }
}
