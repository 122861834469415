@mixin grid {
  max-width: var(--maxwidth);
  margin: 0 var(--margin);
  display: grid;
  grid-template-columns: repeat(var(--cols), 1fr);
  column-gap: var(--gutter);

  @include desktop {
    margin: 0 auto;
  }
}
