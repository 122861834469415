:root {
  --maxwidth: 72.375rem;
  --margin: 1.5rem;
  --gutter: 1.875rem;
  --cols: 9;

  --color-text: #000000;
  --color-pink: #e5007d;
  --color-blue: #009fe3;
  --color-success: #00E59F
  --color-error: #E50058;
  --color-gray: #f2f2f2;
  --color-white: #ffffff;

  --space-20: 1.25rem;
  --space-30: 1.875rem;
  --space-42: 2.625rem;
  --space-56: 3.5rem;
  --space-72: 4.5rem;
  --space-80: 5rem;
  --space-120: 7rem;

  --hover-shadow: 0.2px 0.5px 0.7px -10px rgba(0, 0, 0, 0.139),
    0.6px 1.3px 1.9px -10px rgba(0, 0, 0, 0.2),
    1.5px 3px 4.5px -10px rgba(0, 0, 0, 0.261),
    5px 10px 15px -10px rgba(0, 0, 0, 0.4);
}
