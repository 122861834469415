.content {
  @include grid;

  h2 {
    margin-bottom: 0.75em;
    color: var(--color-pink);
    text-transform: uppercase;

    @include text-20;

    @include tablet-landscape {
      @include text-35;
    }
  }

  h3 {
    margin-bottom: 0.825em;
    color: var(--color-pink);
    text-transform: uppercase;

    @include text-15;

    @include tablet-landscape {
      @include text-20;
    }
  }

  p + img,
  h2 + img,
  h3 + img {
    margin-top: var(--space-30);

    @include tablet-landscape {
      margin-top: var(--space-56);
    }
  }

  img {
    margin-bottom: var(--space-30);

    @include tablet-landscape {
      margin-bottom: var(--space-56);
    }
  }

  ol,
  ul {
    list-style-position: outside;
    padding-left: 1em;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;

    li::marker {
      color: var(--color-pink);
    }
  }

  li {
    padding-left: 0.25em;
  }

  a {
    text-decoration: underline;
    color: var(--color-blue);
  }

  em {
    font-style: italic;
  }
}

.content__wrapper {
  grid-column: 1 / -1;

  @include tablet-landscape {
    grid-column: 2 / -2;
  }
}
