.front-intro {
  background-color: var(--color-gray);
}

.front-intro__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.front-intro__media,
.front-intro__text {
  grid-column: 1 / -1;

  @include tablet-landscape {
    grid-column: span 1;
  }
}

.front-intro__media {
  margin-bottom: var(--space-30);

  @include tablet-landscape {
    order: 2;
    margin-bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.front-intro__text {
  max-width: calc(var(--maxwidth) / 2);
  padding: 0 var(--margin) 2.5rem;

  @include tablet-landscape {
    order: 1;
    margin-left: auto;
    align-self: center;
    padding: 2em var(--margin);
  }

  @include desktop {
    padding: 3.5rem calc(2 * var(--gutter)) 5rem 0;
  }
}

.front-intro__text__heading {
  margin-bottom: 1em;
  color: var(--color-pink);

  @include text-20;

  @include desktop {
    @include text-30;
  }
}

.front-intro__text__body {
  margin-bottom: 2.5em;
}
